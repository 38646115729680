export const masking = (str: string, leftPad = 0, rightPad?: number): string =>
  str.slice(0, leftPad).padEnd(str.length, `*`) +
  (rightPad ? str.slice(str.length - rightPad) : ``);

export const stripTrailingSlash = (str: string): string =>
  str !== `/` && str.endsWith(`/`) ? str.slice(0, -1) : str;

export const getFullBirthday = (str: string): string =>
  str.substring(6, 7) === '1' ||
  str.substring(6, 7) === '2' ||
  str.substring(6, 7) === '5' ||
  str.substring(6, 7) === '6'
    ? `19${str.substring(0, 6)}`
    : `20${str.substring(0, 6)}`;
