import { breakpoint } from '@/helpers/BreakpointHelper';
import { isBrowser } from '@/helpers/BrowserHelper';
import React, { FC, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

const backdropFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
`;

const backdropFadeOut = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    top: -20px; 
  }
  to {
    top: 0;
  }
`;

const slideOut = keyframes`
  from {
    top: 0;
  }
  to {
    top: -20px;
  }
`;

const ModalContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-y: auto;
`;
const ModalBackdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  animation: ${({ isOpen }) => (isOpen ? backdropFadeIn : backdropFadeOut)}
    0.15s linear;
`;
export const ModalDialog = styled.div<{ isOpen: boolean; maxWidth?: string }>`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || `880px`};
  margin: 1.75rem auto;
  background-color: #fff;
  z-index: 1;
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.15s linear;
  ${breakpoint(`mobile`)} {
    margin: 0 auto;
  }
`;

const Modal: FC<ModalProps> = ({
  children,
  isOpen = true,
  dismissable = false,
  onRequestClose,
  maxWidth,
  ...props
}) => {
  const onBackdropClick = useCallback(() => {
    if (dismissable) {
      onRequestClose();
    }
  }, [dismissable, onRequestClose]);

  if (!isBrowser) {
    return null;
  }

  // createPortal로 document.body에 append
  return createPortal(
    <ModalContainer isOpen={isOpen} {...props}>
      <ModalDialog isOpen={isOpen} maxWidth={maxWidth}>
        {children}
      </ModalDialog>
      <ModalBackdrop isOpen={isOpen} onClick={onBackdropClick} />
    </ModalContainer>,
    document.body,
  );
};

export default Modal;
