import IframeModal from '../IframeModal';
import { NameCheckControllerService } from '@/__generated__/CommonApi';
import { RegularPayInfoVo } from '@/__generated__/FrontApi';
import { isBrowser } from '@/helpers/BrowserHelper';
import { encryptJwsRsaes } from '@/helpers/RsaCryptoHelper';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  VFC,
} from 'react';
import { createPortal } from 'react-dom';
import SessionStorage, {
  SESSION_STORAGE_KEY__DONATE_CRYPTO,
  SESSION_STORAGE_KEY__DONATE_PRIVACY,
} from '@/services/SessionStorage';

export interface NameCheckMemberInfo {
  birthday?: string; // 1996-12-17 00:00:00.0
  email?: string;
  juminNumber?: string;
  memberName?: string;
  mobilePhoneNumber?: string;
  address?: string;
  addressDetail?: string;
  zipCode?: string;
  mailDm: 'Y' | 'N';
  mailEdm: 'Y' | 'N';
  mailSms: 'Y' | 'N';
}

type SirenResult = {
  type: 'name-check';
  resultCode: 'success' | 'failed';
  resultMessage: string;
  memberInfo: NameCheckMemberInfo | null;
  payInfo: RegularPayInfoVo | null;
};

interface SirenParams {
  name: string;
  jumin: string;
  isNameCheckValid: boolean;
}

interface SirenFormData {
  reqInfo: any;
  retUrl: string;
  stateCd?: string;
}

interface SirenNameCheckProps {
  onSuccess?: (result: SirenResult) => void;
  onError?: (result: SirenResult) => void;
  renderSubmitButton: (props: { onClick: () => void }) => ReactElement;
  getParams: () => SirenParams;
}

export const resetDonateEncKey = () => {
  SessionStorage.removeItem(SESSION_STORAGE_KEY__DONATE_CRYPTO);
  SessionStorage.removeItem(SESSION_STORAGE_KEY__DONATE_PRIVACY);
};

export const getDonateEncKey = () => {
  try {
    const encKey = JSON.parse(
      SessionStorage.getItem(SESSION_STORAGE_KEY__DONATE_CRYPTO) || '{}',
    );
    const now = new Date().getTime();
    if (encKey.key && encKey.expiry > now) {
      return encKey;
    }
  } catch {}
  return null;
};

const SirenNameCheck: VFC<SirenNameCheckProps> = ({
  renderSubmitButton,
  onSuccess,
  onError,
  getParams,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [keyGenState, setKeyGenState] = useState(false);
  const setDonateEncKey = useCallback(() => {
    // 중복 생성 방지
    if (keyGenState === true) {
      //console.log('++ enc key gen : 암호화키 생성 중복요청 (skip)');
      return;
    }
    setKeyGenState(true);
    const now = new Date().getTime();
    NameCheckControllerService.generateSirenNameCryptoKey()
      .then(({ data }) => {
        //console.log('++ enc key gen : 암호화키 생성시작');
        SessionStorage.setItem(
          SESSION_STORAGE_KEY__DONATE_CRYPTO,
          JSON.stringify({
            key: data.key,
            cert: data.cert,
            expiry: data.expiry,
            isSecureContext: isSecureContext,
          }),
        );
        //console.log('++ enc key gen : 암호화키 생성완료');
      })
      .finally(() => {
        setKeyGenState(false);
      });
  }, [keyGenState]);
  if (!getParams().isNameCheckValid && !getDonateEncKey()) {
    setDonateEncKey();
  }
  const start = useCallback(() => {
    if (!formRef.current) {
      return;
    }

    const params = getParams();
    if (params.jumin.length != 13) {
      onError?.({
        type: 'name-check',
        resultCode: 'failed',
        resultMessage: '주민등록번호가 유효하지 않습니다.',
        memberInfo: null,
        payInfo: null,
      });
      return;
    }

    // 개인정보 암호화
    const encKey = getDonateEncKey();
    try {
      if (encKey === null) {
        onError?.({
          type: 'name-check',
          resultCode: 'failed',
          resultMessage: `개인정보를 암호화 할 수 없습니다.\n잠시 후 다시 시도해 주세요.(01)`,
          memberInfo: null,
          payInfo: null,
        });
        // 키 재생성
        setDonateEncKey();
        return;
      }

      const callApi = async () => {
        params.jumin =
          (await encryptJwsRsaes(
            params.jumin,
            encKey.key,
            encKey.isSecureContext,
          )) || '';
        params.name =
          (await encryptJwsRsaes(
            params.name,
            encKey.key,
            encKey.isSecureContext,
          )) || '';

        NameCheckControllerService.sirenNameAuthUsingPost(params, encKey).then(
          ({ data: _data }) => {
            if (!formRef.current) {
              return;
            }
            const data = _data as SirenFormData;

            if (data.stateCd == '01') {
              // 암호화키 없음. 재생성
              onError?.({
                type: 'name-check',
                resultCode: 'failed',
                resultMessage: `개인정보를 암호화 할 수 없습니다.\n잠시 후 다시 시도해 주세요.`,
                memberInfo: null,
                payInfo: null,
              });
              setDonateEncKey();
              return;
            } else if (data.stateCd != '00') {
              onError?.({
                type: 'name-check',
                resultCode: 'failed',
                resultMessage:
                  '실명인증 처리가 불가능합니다. (' + data.stateCd + ')',
                memberInfo: null,
                payInfo: null,
              });
              return;
            }

            setModalOpen(true);

            formRef.current.reqInfo.value = data.reqInfo;
            formRef.current.ok_url.value = data.retUrl;
            formRef.current.submit();

            // 주민등록 번호 저장 (step3 결제요청시 서버 전송)
            SessionStorage.setItem(
              SESSION_STORAGE_KEY__DONATE_PRIVACY,
              params.jumin,
            );
          },
        );
      };
      callApi();
    } catch (error) {
      onError?.({
        type: 'name-check',
        resultCode: 'failed',
        resultMessage: `개인정보를 암호화 할 수 없습니다.\n잠시 후 다시 시도해 주세요.(02)`,
        memberInfo: null,
        payInfo: null,
      });
      console.error('Encryption error:', error);
      return;
    }
  }, [getParams]);

  // postMessage 리스너 등록
  useEffect(() => {
    const callback = (e: MessageEvent<SirenResult>) => {
      const { type, resultCode } = e.data;
      if (type !== `name-check`) {
        return;
      }
      setModalOpen(false);
      if (resultCode === `success`) {
        onSuccess?.(e.data);
      } else {
        onError?.(e.data);
      }
    };
    window.addEventListener(`message`, callback, false);
    return () => window.removeEventListener(`message`, callback);
  }, [onError, onSuccess]);

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      {renderSubmitButton({ onClick: start })}
      {createPortal(
        <form
          ref={formRef}
          name="nameCheckForm"
          method="post"
          action="https://name.siren24.com/servlet/name_check_seed"
          target="popupNameChk"
        >
          <input type="hidden" name="reqInfo" readOnly />
          <input type="hidden" name="ok_url" readOnly />
        </form>,
        document.body,
      )}
      <IframeModal
        isOpen={modalOpen}
        name="popupNameChk"
        maxWidth="500px"
        hideContent
        onRequestClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default SirenNameCheck;
