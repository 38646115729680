import Modal, { ModalProps } from './Modal';
import Spinner from './Spinner';
import React, { FC, useRef } from 'react';
import styled from 'styled-components';

const Iframe = styled.iframe<{ hide: boolean }>`
  ${({ hide }) => hide && `display: none;`}
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

interface IframeModalProps extends ModalProps {
  name: string;
  hideContent?: boolean;
}

const IframeModal: FC<IframeModalProps> = ({ name, hideContent, ...props }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <Modal {...props}>
      <Iframe ref={iframeRef} title="팝업" name={name} hide={!!hideContent} />
      {hideContent && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </Modal>
  );
};

export default IframeModal;
