import { isBrowser } from '@/helpers/BrowserHelper';

export interface Credential {
  identifier: string;
  password: string;
}

/**
 * 빌드타임에는 localStorage가 없어 에러나기 때문에, Storage를 별도로 정의하여 활용
 */
const LocalStorage = {
  // 기본 함수
  getItem(key: string): string | null {
    return isBrowser ? localStorage.getItem(key) : null;
  },
  setItem(key: string, value: string): void {
    if (isBrowser) {
      localStorage.setItem(key, value);
    }
  },
  removeItem(key: string): void {
    if (isBrowser) {
      localStorage.removeItem(key);
    }
  },
  // 응용 함수
  getToken(): string | null {
    return this.getItem(`token`);
  },
  setToken(token: string): void {
    this.setItem(`token`, token);
  },
  clearAuth(): void {
    this.removeItem(`token`);
  },
};

export default LocalStorage;
