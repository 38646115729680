import { SEO_TYPE_COMMON } from '@/helpers/SeoHelper';
import { stripTrailingSlash } from '@/helpers/StringHelper';
import LocalStorage from '@/services/LocalStorage';
import SessionStorage from '@/services/SessionStorage';
import CookiesUtil from '@/services/CookiesUtil';
import { useLocation } from '@reach/router';
import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  getDonateEncKey,
  resetDonateEncKey,
} from '@/components/ThirdParty/SirenNameCheck';

interface SEOProps {
  isArticle?: boolean;
}

/**
 * SEO 컴포넌트
 * 참고 : https://www.gatsbyjs.com/docs/add-seo-component/
 */
const SEO: FC<SEOProps> = React.memo(({ isArticle }) => {
  const { pathname, search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    site,
    allPageSeo: { nodes: pageSeoList },
  } = useStaticQuery<GatsbyTypes.SEOQuery>(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          titleTemplate
          author
          description
          image
          twitterUsername
          url
        }
      }
      allPageSeo {
        nodes {
          id
          title
          targetPageUrl
          seoTypeCode
          seoSubTypeCode
          metaKeywords
          metaDescription
          metaAuthor
          ogTitle
          ogSiteName
          ogImageAttGrpNo
          ogDescription
          imgOgImageAttGrpNo
          imageAltText
          boardCategoryCode
          canonicalUrl
        }
      }
    }
  `);
  const siteSeo = site?.siteMetadata;
  const pagePath = useMemo(() => stripTrailingSlash(pathname), [pathname]);
  const commonSeo = useMemo(
    () =>
      pageSeoList.find(
        (seo) => seo.seoTypeCode === SEO_TYPE_COMMON && seo.id === 'PageSeo-1',
      ),
    [pageSeoList],
  );
  const pageSeo = useMemo(
    () =>
      pageSeoList.find(
        (seo) => pagePath !== '/' && seo.targetPageUrl === pagePath,
      ),
    [pagePath, pageSeoList],
  );

  // 쿼리스트링에 trackcode, trk_id가 있으면 세션스토리지 저장
  useEffect(() => {
    /**
     * unicef_trk 입력을 위한 post
     * written by jinsyu
     */
    let trackcode = queryParams.get(`trackcode`);
    const trkId = queryParams.get(`trk_id`);
    // 후원 페이지 : 캠페인 페이지 유입 확인
    if (pagePath.startsWith('/donate')) {
      // 검증 순서 Cookies > SessionStorage (다른창으로 오픈시 누락 보완)
      const eventTrackCd = CookiesUtil.getCookie('eventTrackCd');
      if (eventTrackCd) {
        try {
          const jsonEventTrackCd =
            typeof eventTrackCd === 'string'
              ? JSON.parse(decodeURIComponent(eventTrackCd))
              : eventTrackCd;

          // querystring 보다 우선순위임
          if (jsonEventTrackCd) {
            trackcode = jsonEventTrackCd.code;
            SessionStorage.setItem(`latest_trkcd`, trackcode || '');
          }
        } catch (error) {
          console.log('unicef_trk error', error);
        }
      } else if (SessionStorage.getItem('latest_trkcd')) {
        trackcode = SessionStorage.getItem('latest_trkcd');
      }
    } else {
      CookiesUtil.removeCookie('eventTrackCd');
      SessionStorage.removeItem('latest_trkcd');
    }
    // 개인정보 암호화키 삭제 (후원중 이탈)
    if (
      !pagePath.startsWith('/donate') &&
      !pagePath.startsWith('/involve/individual/ig/form') &&
      !pagePath.startsWith('/unicef/api/fo/common/payment')
    ) {
      if (getDonateEncKey()) {
        // console.log('+ 후원진행 중 이탈', pagePath);
        resetDonateEncKey();
      }
    }
    if (trackcode) {
      // console.log(trackcode, trkId, pathname, search);
      axios
        .post(
          `${process.env.BACKEND_URL}/unicef/api/fo/common/unicef_trk`,
          {
            trk_code: trackcode ?? ``,
            trk_URL: pathname ?? ``,
            trk_id: trkId ?? 0,
          },
          {
            headers: {
              'X-AUTH-TOKEN': LocalStorage.getToken(),
            },
          },
        )
        .then((_) =>
          console.log('unicef_trk inserted', trackcode, pathname, trkId),
        )
        .catch((e) => console.error(e));
    }

    if (trackcode) {
      SessionStorage.setItem(`trackcode`, trackcode);
    }
    if (trkId) {
      SessionStorage.setItem(`trk_id`, trkId);
    }
  }, [queryParams]);

  const decodeHTMLEntities = (text: string | undefined) => {
    if (text) {
      return text
        .replaceAll(/&amp;/g, '&')
        .replaceAll(/&lt;/g, '<')
        .replaceAll(/&gt;/g, '>')
        .replaceAll(/&quot;/g, '"')
        .replaceAll(/&#039;/g, "'");
    }
    return null;
  };

  // 우선순위에 따라 있는 데이터들을 or 조건으로 적용
  const seo = {
    title:
      decodeHTMLEntities(pageSeo?.title) || commonSeo?.title || siteSeo?.title,
    description:
      decodeHTMLEntities(pageSeo?.metaDescription) ||
      commonSeo?.metaDescription ||
      siteSeo?.description,
    author:
      decodeHTMLEntities(pageSeo?.metaAuthor) ||
      commonSeo?.metaAuthor ||
      siteSeo?.author,
    keywords: pageSeo?.metaKeywords || commonSeo?.metaKeywords,
    ogTitle:
      decodeHTMLEntities(pageSeo?.ogTitle) ||
      commonSeo?.ogTitle ||
      siteSeo?.title,
    ogDescription:
      decodeHTMLEntities(pageSeo?.ogDescription) ||
      commonSeo?.ogDescription ||
      siteSeo?.description,
    ogImage:
      pageSeo?.ogImageAttGrpNo ||
      commonSeo?.ogImageAttGrpNo ||
      (siteSeo ? `${siteSeo.url}${siteSeo.image}` : ``),
    ogUrl: siteSeo ? `${siteSeo.url}${pathname}` : ``,
    twitterUsername: siteSeo?.twitterUsername || ``,
  };
  return (
    <Helmet title={seo.title} titleTemplate={siteSeo?.titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.ogImage} />
      <meta name="author" content={seo.author} />
      <meta name="keywords" content={seo.keywords} />
      {/* og data */}
      {isArticle && <meta property="og:type" content="article" />}
      {seo.ogUrl && <meta property="og:url" content={seo.ogUrl} />}
      {seo.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
      {seo.ogDescription && (
        <meta property="og:description" content={seo.ogDescription} />
      )}
      {seo.ogImage && <meta property="og:image" content={seo.ogImage} />}
      {/* twitter data */}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.twitterUsername && (
        <meta name="twitter:creator" content={seo.twitterUsername} />
      )}
      {seo.ogTitle && <meta name="twitter:title" content={seo.ogTitle} />}
      {seo.ogDescription && (
        <meta name="twitter:description" content={seo.ogDescription} />
      )}
      {seo.ogImage && <meta name="twitter:image" content={seo.ogImage} />}
      {pageSeo && pageSeo.canonicalUrl && (
        <link rel="canonical" href={pageSeo.canonicalUrl} />
      )}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${siteSeo?.url}",
          "name": "unicef",
          "legalName": "unicef korea",
          "alternateName": "www.unicef.or.kr",
          "logo": "https://www.unicef.or.kr/images/seo/unicef_225x120.png",
          "sameAs": [
            "https://blog.naver.com/unicefgarden",
            "https://www.youtube.com/user/unicefMedia/featured",
            "https://twitter.com/unicefkorea",
            "https://www.facebook.com/unicefkr",
            "https://www.instagram.com/unicef_kr",
            "https://story.kakao.com/ch/unicefweaction"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+82-2-737-1004",
            "contactType": "customer service",
            "contactOption": "TollFree",
            "areaServed": "KR",
            "availableLanguage": "KO"
          }
        }
      `}
      </script>
    </Helmet>
  );
});

export default SEO;
