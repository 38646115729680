import * as jose from 'jose';
import forge from 'node-forge';

const importKey = (pemEncodedKey : string) => {
  // helper function
  // from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
  const str2ab = (str: string) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  // main function to load the public key
  // and convert it to crypto key
  // for encryption in browser
  const importRsaKey = (pem : string) => {
    // fetch the part of the PEM string between header and footer
    // replace this
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";

    const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);

    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pemContents);
    
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);

    return window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    );
  }
  return importRsaKey(pemEncodedKey);
}

export const encryptJwsRsaes = async (plaintext: string, publicKey: string, isSecureContext: boolean) => {
  const buffPublicKey = Buffer.from(publicKey, 'base64');
  const pemPublicKey = `-----BEGIN PUBLIC KEY-----\n${buffPublicKey.toString('base64').match(/.{1,64}/g)?.join('\n')}\n-----END PUBLIC KEY-----`;

  console.log('+++ isSecureContext : ', isSecureContext);
  if (isSecureContext===true) {
    // 보안서버일 경우
    const alg = 'RSA-OAEP-256';
    //const rsaPublicKey = await importKey(pemPublicKey);
    const rsaPublicKey = await jose.importSPKI(pemPublicKey, alg);
    const encrypt = await new jose.CompactEncrypt(
        new TextEncoder().encode(plaintext),
    )
    .setProtectedHeader({ alg: alg, enc: 'A256CBC-HS512' })
    .encrypt(rsaPublicKey);
    
    return encrypt;
  } else {
    // 개발서버에서 SecureContext 환경이 아닌 상태로 window.crypto.subtle.importKey 사용시 오류 발생
    const alg = 'RSA-OAEP';
    try {
      const rsaPublicKey = forge.pki.publicKeyFromPem(pemPublicKey);
      const encryptMsg = forge.util.encodeUtf8(plaintext);

      // rsa encrypt
      // encrypt data with a sign key (defaults to RSAES-PKCS1-V1_5)
      const encrypt = forge.util.encode64(rsaPublicKey.encrypt(encryptMsg, alg , {
        md: forge.md.sha256.create(),
          mgf1: {
            md: forge.md.sha1.create()
          },
        }
      ));

      return encrypt;
    } catch (e) {
      console.log(e);
    }
  }
  return null;
}
