import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const CookiesUtil = {
  setCookie(name: string, value: string, options?: any) : void {
    /** exam
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    setCookie('쿠키명', '저장할 문자', {
      path: '/',
      secure: '/',
      expires: expires;
    });
    */
    cookies.set(name, value, {...options}); 
  },
  getCookie(name: string) : string | null {
    /** exam
    getCookie('쿠키명');
    */
    return cookies.get(name);
  },
  removeCookie(name: string, options?: any) : void {
    cookies.remove(name, options);
  }
};
export default CookiesUtil;


