import { isBrowser } from '@/helpers/BrowserHelper';

/* 후원 > 개인정보 암호화키 */
export const SESSION_STORAGE_KEY__DONATE_CRYPTO = '__donateCrypto';
/* 후원 > 개인정보 암호화 데이터(jumin) */
export const SESSION_STORAGE_KEY__DONATE_PRIVACY = '__donatePrivacy';
/* 마이페이지 > 개인정보 > 인증토큰 */
export const SESSION_STORAGE_KEY__MYPAGE_AUTH = '__myinfoAuth';

/**
 * 빌드타임에는 sessionStorage가 없어 에러나기 때문에, Storage를 별도로 정의하여 활용
 */
const SessionStorage = {
  // 기본 함수
  getItem(key: string): string | null {
    return isBrowser ? sessionStorage.getItem(key) : null;
  },
  setItem(key: string, value: string): void {
    if (isBrowser) {
      sessionStorage.setItem(key, value);
    }
  },
  removeItem(key: string): void {
    if (isBrowser) {
      sessionStorage.removeItem(key);
    }
  },
  clear(): void {
    if (isBrowser) {
      sessionStorage.clear();
    }
  },
};

export default SessionStorage;
